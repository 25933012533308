import React from 'react'
import moment from 'moment'
import 'moment/locale/es'

const ApplicationFormProinnovate = ({dates, ctaApplication, applicationTitle, applicationSubtitle, applicationDetails}) => {
  return (
    <div className='section section-ApplicationForm'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-lg-7 ApplicationForm-Side-Container'>
            <div className='ApplicationForm-info'>
              <h2>{ applicationTitle || "¿Quieres unirte?"}</h2>
              <h3> { applicationSubtitle || "Llena tu aplicación ahora."}</h3>
              <h5>
                { 
                  applicationDetails ||  `Recuerda! Llena a detalle cada uno de los campos de la
                  aplicación para mejorar tus posibilidades de continuar con el
                  proceso.`
                }
              </h5>
            </div>
          </div>
          <div className='col-sm-12 col-lg-5 ApplicationForm-Side-Container'>
            <div className='AplicationForm-dates-container'>
              {dates &&
                dates.map((date, index) => (
                  <div className='application-form-date' key={index}>
                    <span>{date.label}</span>
                    <h4>{moment(date.date).locale('es').format('MMMM D')}</h4>
                  </div>
                ))}              
              
                <div className='AplicationForm-btn'>
                  <div className='AplicationForm-btn'>
                    <a
                      href='/'
                      className='pbtn pbtn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#exampleModal'
                    >
                      { ctaApplication || "APLICA AHORA" }
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ApplicationFormProinnovate.defaultProps = {}

export default ApplicationFormProinnovate
