import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import BlockManager from '../components/shared/BlockManager'

const PageTemplate = ({data, location}) => {
  const page = data.strapiPage

  return (
    <Layout global={data.strapiGlobal} location={location}>
      {page.blocks && (
        <BlockManager blocks={page.blocks} seo={page.seo} title={page.title} location={location} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($title: String!) {
    strapiPage(title: {eq: $title}) {
      title
      seo {
        metaTitle
        metaDescription
        metaImage {
          url
          alternativeText
        }
        metaSocial {
          socialNetwork
          title
          description
          image {
            url
            alternativeText
          }
        }
        keywords
        metaRobots
        structuredData
        metaViewport
        canonicalURL
      }
      blocks {
        ... on StrapiComponentBlocksHero {
          __typename
          id
          header {
            description
            label
            title
          }
          image {
            alternativeText
            caption
            name
            previewUrl
            url
          }
          content
          applyNowButton
          backgroundColor
          ctaHero
        }
        ... on StrapiComponentBlocksHeroProinnovate {
          __typename
          id
          header {
            description
            label
            title
          }
          image {
            alternativeText
            caption
            name
            previewUrl
            url
          }
          content
          applyNowButton
          backgroundColor
          ctaHero
        }
        ... on StrapiComponentBlocksCompanies {
          __typename
          id
          header {
            description
            label
            title
          }
          companies {
            name
            link {
              href
              isExternal
              label
              target
            }
            logo {
              url
              alternativeText
            }
          }
        }
        ... on StrapiComponentBlocksAchievement {
          __typename
          fulfilment {
            list {
              popup
              title
              content
            }
            title
          }
          header {
            description
            label
            title
          }
          student_reviews {
            country
            company
            createdAt
            image {
              url
              alternativeText
            }
            name
            program_finished_at
            new_job_at
            role
          }
        }
        ... on StrapiComponentBlocksMentors {
          __typename
          header {
            description
            label
            title
          }
          mentors {
            avatar {
              url
              alternativeText
            }
            name
            role
            company_logo {
              url
              alternativeText
            }
          }
        }
        ... on StrapiComponentBlocksMetodology {
          __typename
          header {
            description
            label
            title
          }
          program_phases {
            id
            finish_date
            finish_date_label
            duration
            description
            image {
              url
              alternativeText
            }
            label
            name
            start_date
            start_date_label
            title
          }
        }
        ... on StrapiComponentBlocksPricing {
          __typename
          header {
            description
            label
            title
          }
          payments {
            unit
            country
            installments {
              price
              title
              type
              content
              how_it_works {
                description
              }
              type
              priceWithoutDiscount
              emphasis
            }
          }
        }
        ... on StrapiComponentBlocksProcess {
          __typename
          header {
            title
            label
            description
          }
          steps {
            description
            title
            image {
              url
              previewUrl
            }
          }
        }
        ... on StrapiComponentBlocksRequirement {
          __typename
          header {
            description
            label
            title
          }
          image {
            url
            alternativeText
          }
          requirements {
            description
            title
            image {
              url
              alternativeText
            }
          }
        }
        ... on StrapiComponentBlocksResume {
          __typename
          header {
            description
            label
            title
          }
          reasons {
            id
            description
            title
            image {
              alternativeText
              url
            }
          }
        }
        ... on StrapiComponentBlocksReviews {
          __typename
          student_reviews {
            company
            country
            name
            new_job_at
            program_review
            program_finished_at
            publishedAt
            role
            strapiId
            createdAt
            image {
              url
              previewUrl
            }
          }
        }
        ... on StrapiComponentBlocksTestimonial {
          __typename
          student {
            company
            country
            createdAt
            image {
              url
              previewUrl
            }
            name
            new_job_at
            program_finished_at
            program_review
            role
          }
        }
        ... on StrapiComponentBlocksTestimonials {
          __typename
          students {
            company
            country
            createdAt
            image {
              url
              previewUrl
            }
            name
            new_job_at
            program_finished_at
            program_review
            role
          }
        }
        ... on StrapiComponentBlocksWhy {
          __typename
          header {
            description
            label
            title
          }
          image {
            url
            alternativeText
          }
          reasons {
            description
            title
            image {
              url
              alternativeText
            }
          }
        }
        ... on StrapiComponentSharedCardDetail {
          __typename
          detail {
            description
            label
            title
          }
          image {
            url
            alternativeText
          }
        }
        ... on StrapiComponentBlocksQuestions {
          __typename
          program_questions {
            title
            description
          }
          header {
            description
            label
            title
          }
        }
        ... on StrapiComponentBlocksApplicationForm {
          __typename
          dates {
            label
            date
          }
          ctaApplication
          applicationTitle 
          applicationSubtitle 
          applicationDetails
        }
        ... on StrapiComponentBlocksApplicationFormProinnovate {
          __typename
          dates {
            label
            date
          }
          ctaApplication
          applicationTitle 
          applicationSubtitle 
          applicationDetails
        }
        ... on StrapiComponentSharedApplyNow {
          __typename
          label
          steps {
            description
            title
            smallerText
          }
          redirectToUrl
          applicantCreationUrl
        }
        ... on StrapiComponentBlocksRegistrationModal {
          __typename
          redirectToUrl
          leadRegistrationUrl
        }
        ... on StrapiComponentBlocksRegistrationModalId {
          __typename
          redirectToUrl
          leadRegistrationUrl
          convertLoopEventName
          programName
          formHeader
          formDescription
          formCta
        }
        ... on StrapiComponentBlocksProinnovateForm {
          __typename
          redirectToUrl
          leadRegistrationUrl
          convertLoopEventName
          programName
          formCta
        }
        ... on StrapiComponentBlocksPaymentModalProinnovate {
          __typename
          paymentHeader
          paymentDescription 
          ctaCardPaymentOne 
          ctaCardPaymentTwo
        }
        ... on StrapiComponentBlocksSponsor {
          __typename
          label
          sponsors {
            image {
              url
              alternativeText
            }
            name
            link
          }
        }
      }
    }
    strapiGlobal {
      navigation {
        backgroundColor
        leftButton {
          isExternal
          label
          target
          href
        }
        rightButton {
          href
          isExternal
          label
          labelText
          target
        }
        links {
          href
          isExternal
          label
          target
          groupTitle
        }
      }
    }
  }
`

export default PageTemplate
