import React, {useState} from 'react'

const MoreReviewsButton = ({
  reviews,
  setReviews,
  pageInfo,
  setPageInfo,
  paginate,
}) => {
  const [showMore, setShowMore] = useState(true)
  const handleClick = () => {
    setReviews((last) => [
      ...last,
      ...paginate(reviews, pageInfo.limit, pageInfo.currentPage + 1),
    ])
    setPageInfo((last) => ({
      ...last,
      currentPage: pageInfo.currentPage + 1,
    }))
    if (pageInfo.currentPage === pageInfo.totalPages) {
      setShowMore(false)
    }
  }

  if (!showMore && typeof window !== 'undefined') {
    window.open('https://www.facebook.com/makeitrealcamp/reviews', '_blank')
  }

  return (
    <div className={`section-more-reviews-btn ${!showMore && 'no-more'}`}>
      <button
        id='moreReviews'
        className={`pbtn pbtn-primary ${!showMore && 'd-none'} `}
        onClick={handleClick}
      >
        VER MÁS COMENTARIOS
      </button>
      <a
        href='https://www.facebook.com/makeitrealcamp/reviews'
        className={`pbtn pbtn-primary ${showMore && 'd-none'} `}
        target='_blank'
      >
        VER MÁS COMENTARIOS
      </a>
    </div>
  )
}

MoreReviewsButton.defaultProps = {}

export default MoreReviewsButton
