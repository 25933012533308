import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Cards = ({companies}) => {
  return (
    <div className='row'>
      {companies &&
        companies.map((item, index) => {
          return (
            <div className='col-6 col-sm-4 col-lg-2' key={index}>
              <div className='company'>
                <a
                  href={delve(item, 'link.href')}
                  target={delve(item, 'link.target')}
                >
                  <img
                    src={getStrapiMedia(delve(item, 'logo.url'))}
                    alt={delve(item, 'logo.alternativeText')}
                  />
                </a>
              </div>
            </div>
          )
        })}
    </div>
  )
}

Cards.defaultProps = {}

export default Cards
