import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const PhaseDetail = ({label, title, duration, description, image}) => {
  return (
    <div className='phase'>
      <div className='row'>
        <div className='col-12 col-md-7 d-flex align-items-center'>
          <div className='phase-info'>
            <div>
              <h6>{label}</h6>
              <h1>{title}</h1>
              <h5>{duration}</h5>
            </div>
            <div className='cont-desc'>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div className='d-none d-md-block col-md-5'>
          <div className='phase-img'>
            <img
              src={getStrapiMedia(delve(image, 'url'))}
              alt={delve(image, 'alternativeText')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

PhaseDetail.defaultProps = {}

export default PhaseDetail
