import React, { useState } from 'react';
import {navigate} from 'gatsby';
import {postFormData} from '/src/api';


const RegistrationModal = ({redirectToUrl, leadRegistrationUrl}) => {
  const [formValues, setFormValues] = useState({
    accepted_terms: false,
    first_name: '',
    last_name: '',
    email: '',
    country:'',
    mobile:'',
    "program-name": 'QA Tester',
    "convertloop-event": 'applied-to-qa-manual-tester'
  });

  const countryOptions = [
    ['País de residencia *', ''],
    ['Argentina', 'AR', {data: {code: '54'}}],
    ['Bolivia', 'BO', {data: {code: '591'}}],
    ['Chile', 'CL', {data: {code: '56'}}],
    ['Colombia', 'CO', {data: {code: '57'}}],
    ['Costa Rica', 'CR', {data: {code: '506'}}],
    ['Ecuador', 'EC', {data: {code: '593'}}],
    ['El Salvador', 'SV', {data: {code: '503'}}],
    ['España', 'ES', {data: {code: '34'}}],
    ['Estados Unidos', 'US', {data: {code: '1'}}],
    ['Guatemala', 'GT', {data: {code: '502'}}],
    ['Honduras', 'HN', {data: {code: '504'}}],
    ['México', 'MX', {data: {code: '52'}}],
    ['Nicaragua', 'NI', {data: {code: '505'}}],
    ['Panamá', 'PA', {data: {code: '507'}}],
    ['Paraguay', 'PY', {data: {code: '595'}}],
    ['Perú', 'PE', {data: {code: '51'}}],
    ['Puerto Rico', 'PR', {data: {code: '1'}}],
    ['República Dominicana', 'DO', {data: {code: '1'}}],
    ['Uruguay', 'UY', {data: {code: '598'}}],
    ['Venezuela', 'VE', {data: {code: '58'}}],
  ]

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [country, setCountry] = useState(null)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    
    setFormValues((prevState) => ({
      ...prevState,
      [name]: fieldValue
    }));
  };

  const handleCountryChange = (e) => {
    const { name, value} = e.target;
    const fieldValue = value;
    const country = countryOptions.find((option) => option[1] === e.target.value)
    setCountry(country[2].data.code)
    setFormValues((prevState) => ({
      ...prevState,
      [name]: fieldValue
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors(null);

    const newErrors = validateForm(formValues);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
    try {
      const response = await postFormData(leadRegistrationUrl, formValues)
      
      if (response.message === 'Success') {
        document.querySelector('#exampleModal').classList.remove('show')
        document.querySelector('.modal-backdrop').remove()
        navigate(redirectToUrl)
      } else {
        setErrors('An error occurred while submitting the form.')
      }
    } catch (error)  {
        setErrors('An error occurred while submitting the form.')
    } finally {
       setIsLoading(false);
    };        
   
  };

  setIsLoading(false);
};

  const validateForm = (values) => {
    const errors = {};

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (!values[key]) {
          errors[key] = 'Este campo es obligatorio';
        }
      }
    }

    return errors;
  };

  return (
    <>
      <div
        className='modal modal-lg fade '
        id='exampleModal'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            
            <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
               <p><strong>Solicitud de información</strong></p>
                <p><small>Recibe en tu correo toda la información del curso de QA Manual Testing.</small></p>
              </h1>
            
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            <div className='modal-wrapper'>
              <form 
                id='application-form'
                className='row g-3'
                onSubmit={handleSubmit}
              >
                <div className='modal-body'>
                  
                  <div className = 'application-1'>
                    
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='first-name'>
                              Nombre:
                              <input
                                type="text"
                                id='first-name'
                                name="first_name"
                                className='form-control'
                                placeholder='Nombres'
                                value={formValues.first_name}
                                onChange={handleChange}
                              />
                          </label>
                          {errors.first_name && <div className='error-message'>{errors.first_name}</div>}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='first-name'>
                            Apellido:
                            <input
                              type="text"
                              id='last-name'
                              name="last_name"
                              className='form-control'
                              placeholder='Apellidos'
                              value={formValues.last_name}
                              onChange={handleChange}
                            />
                          </label>
                          { errors.last_name &&  <div className='error-message'>{errors.last_name}</div>}
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='country'>
                            País de residencia
                          </label>
                          <select
                            name='country'
                            id='country'
                            className='form-control'
                            onChange={handleCountryChange}
                          >
                            {countryOptions.map(([name, value]) => (
                              <option key={value} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                            {errors.country && <div className='error-message'>{errors.country}</div>}
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className='form-group' id='mobile-wrapper'>
                          <label htmlFor='mobile'>
                            Teléfono
                          </label>
                            <div className='input-group'>
                              <span
                                className='input-group-text'
                                id='mobile-code'
                              >
                                {!country ? '+00' : `+${country}`}
                              </span>
                            
                              <input
                                type='tel'
                                id='mobile'
                                name='mobile'
                                className='form-control'
                                value={formValues.mobile}
                                placeholder='Número móvil'
                                onChange={handleChange}
                              />
                            </div>
                         
                          {errors.email && <div className='error-message'>{errors.mobile}</div>}
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label htmlFor='email' ></label>
                            Email:
                            <input
                              type="email"
                              name="email"
                              className='form-control'
                              value={formValues.email}
                              onChange={handleChange}
                            />
                          {errors.email && <div className='error-message'>{errors.email}</div>}   
                        </div>
                      </div>  
                    </div>
                    
                    <div className='checkbox' style={{marginTop: '50px'}}>
                      <label>
                        <input
                          type="checkbox"
                          id='terms'
                          name="accepted_terms"
                          checked={formValues.accepted_terms}
                          onChange={handleChange}
                        />{' '}
                        Autorizo el uso de mis datos de acuerdo a la{' '}
                        <a
                          href={`${process.env.EXTERNAL_URL}/privacy-policy`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Política de Tratamiento de Información
                        </a>
                      </label>
                      {errors.accepted_terms && <div className='error-message'>{errors.accepted_terms}</div>}  
                    </div>                    
                    <div className='modal-footer'>
                      <button 
                        type="submit"
                        className='pbtn pbtn-primary btn-lg'
                        disabled={isLoading}
                      >
                        Recibir Información
                      </button>
                    </div>
              </div>   
            </div>                               
          </form>
        </div>
      </div>
    </div>
  </div>
</>
  );
};

export default RegistrationModal;