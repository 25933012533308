import React from 'react'
import Navbar from './global/Navbar'
import ContactUs from './blocks/ContactUs'
import {StaticImage} from 'gatsby-plugin-image'

const Layout = ({children, global, location}) => {
  const isFormPage = location.pathname.startsWith('/formulario');

  return (
    <div className='pages-layout'>
      <header className='bg-primary-gradient'>
        {isFormPage ? null : <Navbar {...global} location = {location} />}
        {children}
        {isFormPage ? null : <ContactUs />}
        <div className='whatsapp'>
          <a
            href='https://api.whatsapp.com/send/?phone=573197378826&text=Hola%21%20Me%20gustar%C3%ADa%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20programas%20que%20ofrecen&type=phone_number&app_absent=0'
            className='whatsapp-link'
            target='_blank'
            rel='noreferrer'
          >
            <StaticImage
              src='../images/whatsapp.png'
              formats={['AUTO', 'WEBP']}
              alt='whatsapp make it real'
            />
          </a>
        </div>
      </header>
    </div>
  )
}

export default Layout
