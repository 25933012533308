import React from 'react'
import PhaseDetail from './PhaseDetail'

const PhaseList = ({phases}) => {
  return (
    <div className='phases'>
      <div className='container tab-menu'>
        <ul className='nav nav-pills' id='pills-tabb'>
          {phases.map((phase, index) => (
            <li className='nav-item' role='presentation' key={index}>
              <button
                className={`nav-link ${index === 0 && 'active'}`}
                id={`pills-${phase.id}-tab`}
                data-bs-toggle='tab'
                data-bs-target={`#pills-${phase.id}`}
                type='button'
                role='tab'
                aria-controls={`pills-${phase.id}`}
                aria-selected={index === 0 ? 'true' : 'false'}
              >
                <span>0{index + 1}</span>
              </button>
              <label htmlFor={`pills-${phase.id}-tab`}>{phase.title}</label>
            </li>
          ))}
        </ul>
      </div>
      <div className='container-fluid tab-content' id='pills-tabContentt'>
        {phases.map((phase, index) => (
          <div
            key={index}
            className={`phase tab-pane fade ${index === 0 && 'show active'}`}
            id={`pills-${phase.id}`}
            role='tabpanel'
            aria-labelledby={`pills-${phase.id}-tab`}
          >
            <PhaseDetail {...phase} />
          </div>
        ))}
      </div>
    </div>
  )
}

PhaseList.defaultProps = {}

export default PhaseList
