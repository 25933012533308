import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Sponsor = ({label, sponsors}) => {
  return (
    <div className='section section-sponsor'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='sponsor-info'>
              <div className="sponsor-label">{label}</div>
              {sponsors.map((sponsor, index) => (
                <div>
                  <img
                    src={getStrapiMedia(delve(sponsor.image, 'url'))}
                    alt={delve(sponsor.image, 'alternativeText')}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Sponsor.defaultProps = {}

export default Sponsor
