import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Reasons = ({reasons}) => {
  return (
    <div className='list-items reasons'>
      {reasons &&
        reasons.map((item, index) => (
          <div className='item' key={`reason-${index}`}>
            <div className='item-icon'>
              <img
                src={getStrapiMedia(delve(item, 'image.url'))}
                alt={delve(item, 'image.alternativeText')}
              />
            </div>
            <div className='item-info'>
              {item.title && <h6>{item.title}</h6>}
              {item.description && <p>{item.description}</p>}
            </div>
          </div>
        ))}
    </div>
  )
}

Reasons.defaultProps = {}

export default Reasons
