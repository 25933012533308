import React from 'react'
import HowItWorksList from './HowItWorksList'

const DarkCard = ({installment, index}) => {
  return (
    <div className='col-md-6 col-lg-4 item' key={index}>
      <div
        className={`card installment-item ${installment.emphasis ? 'dark-container' : null}`}
        key={`installment-${index}`}
      >
        <div className='card-header'>
          {installment.emphasis ? null : <span>{installment.type}</span> }
          <h4 className='card-text'>{installment.title}</h4>
          <br />
          <h6>{installment.content}</h6>
          {installment.priceWithoutDiscount && (
            <h3
              className={`card-title disable-line ${
                index === 0 ? 'fw-bolder' : 'fw-normal'
              }`}
            >
              ${installment.priceWithoutDiscount.toLocaleString(installment.country)}
            </h3>
          )}
          <div
            className={`card-title price-value ${
              index === 0 ? 'fw-bolder' : 'fw-normal'
            }`}
          >
            ${installment.price.toLocaleString(installment.country)}
          </div>
          {installment.emphasis ? <div class='price-tag'>{installment.type}</div> : null}
        </div>
        <div className='card-body'>
          <h4>CÓMO FUNCIONA</h4>
          <HowItWorksList list={installment.how_it_works} />
        </div>
      </div>
    </div>
  )
}

DarkCard.defaultProps = {}

export default DarkCard
