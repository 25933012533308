import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'
import Header from '../../shared/Header'

const OurStory = ({image, detail}) => {
  return (
    <div className='section section-OurStory'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 img-row'>
            <div className='img-container'>
              <img
                src={getStrapiMedia(delve(image, 'url'))}
                alt={delve(image, 'alternativeText')}
              />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='description-container'>
              <Header {...detail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OurStory.defaultProps = {}

export default OurStory
