import React from 'react'
import TechnologyCards from './Technologies'
import Header from '../../shared/Header'

const Resume = ({header, reasons}) => {
  return (
    <div className='section section-resume'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <Header {...header} />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <TechnologyCards technologies={reasons} />
          </div>
        </div>
      </div>
    </div>
  )
}

Resume.defaultProps = {}

export default Resume
