import React from 'react'
import Header from '../../shared/Header'
import ReasonCards from './reasons'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Why = ({image, header, reasons}) => {
  return (
    <div className='section section-why'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='why-img'>
              <img
                src={getStrapiMedia(delve(image, 'url'))}
                alt={delve(image, 'alternativeText')}
              />
            </div>
          </div>
          <div className='col-lg-6'>
            <Header {...header} />
            <ReasonCards reasons={reasons} />
          </div>
          
        </div>
      </div>
    </div>
  )
}

Why.defaultProps = {}

export default Why
