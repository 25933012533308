import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

const Columns = ({list}) => {
  return (
    <div className='col-md-6'>
      {list &&
        list.map((item) => (
          <div className='item' key={`tech-${item.id}`}>
            <div className='item-icon'>
              <StaticImage src='../../../images/check.svg' alt={`icon`} />
            </div>
            <div className='item-info'>
              {item.title && <h6>{item.title}</h6>}
              {item.description && <p>{item.description}</p>}
            </div>
          </div>
        ))}
    </div>
  )
}

Columns.defaultProps = {}

export default Columns
