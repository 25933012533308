import React from 'react'

const HowItWorksList = ({list}) => {
  return (
    <div className='list-items'>
      {list.map((item, index) => (
        <div className='item' key={`requirement-${index}`}>
          <div className='item-icon'>
            <i className='gg-check'></i>
          </div>
          <div className='item-info'>{<p>{item.description}</p>}</div>
        </div>
      ))}
    </div>
  )
}

HowItWorksList.defaultProps = {}

export default HowItWorksList
