import React from 'react'
import PropTypes from 'prop-types'

import StudentList from './StudentList'
import ReactTooltip from 'react-tooltip'

const Achievement = ({
  header,
  fulfilment,
  title = 'Estos son nuestros egresados en números.',
  student_reviews,
}) => {
  return (
    <div className='section section-achievement'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-7'>
            <div className='info-section-achievement'>
              <div className='titlee'>
                {header?.title && (
                  <h5 className='title mb-4'>{header.title}</h5>
                )}
                {header?.title && <h6 className='subtitle mb-4'>{title}</h6>}
              </div>

              <ReactTooltip
                place='bottom'
                effect='solid'
                border={true}
                backgroundColor='#fff'
                textColor='$color-orange'
                borderColor='#C4C4C4'
                className='tooltip-custom-style'
                arrowSize={100}
              />
              {fulfilment && (
                <div className='row'>
                  {fulfilment.list.map((item, index) => (
                    <div
                      className='col-md-4 fulfilment-item'
                      key={`fulfilment-${index}`}
                    >
                      <h4>{item.title}</h4>
                      <p>
                        {item.content.replaceAll('*', '')}
                        {item.popup && (
                          <span data-tip={item.popup}>
                            <b>*</b>
                          </span>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='col-lg-4 offset-lg-1 justify-content-center'>
            <div className='students-container'>
              <StudentList students={student_reviews} />
              <div className='d-flex justify-content-center pt-5'>
                <button
                  className='pbtn pbtn-primary border-0 apply'
                  data-bs-toggle='modal'
                  data-bs-target='#exampleModal'
                >
                  Aplica Ahora
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Achievement.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
  }),
  fulfilment: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        popup: PropTypes.string,
      })
    ),
  }),
  student_reviews: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      company: PropTypes.string,
      program_review: PropTypes.string,
    })
  ),
}

export default Achievement
