import React, {useEffect} from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'
import {StaticImage} from 'gatsby-plugin-image'
import {addCarouselSwipeGesture} from '../../../scripts/carousel'

const Testimonials = ({students}) => {
  useEffect(() => {
    addCarouselSwipeGesture()
  }, [])
  return (
    <div className='section section-testimonials'>
      <div className='container'>
        <div className='students-reviews'>
          <div
            id='testimonialsCarousel'
            className='carousel'
            data-bs-ride='carousel'
          >
            {students &&
              students.map((student, index) => (
                <div className='carousel-inner'>
                  <div
                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                    key={`testimonial-${index}`}
                  >
                    <div className='row d-flex justify-content-center'>
                      <div className='col-lg-5'>
                        <div className='student-img'>
                          <img
                            src={getStrapiMedia(delve(student.image, 'url'))}
                            alt={delve(student.image, 'alternativeText')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='student-review'>
                          <StaticImage
                            src='../../../images/testimonial-icon.svg'
                            formats={['AUTO', 'WEBP']}
                            alt='Testimonial icon'
                          />
                          <p>{student.program_review}</p>
                          <h5>{student.name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonials.defaultProps = {}

export default Testimonials
