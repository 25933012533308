import React from 'react'
import Header from '../../shared/Header'
import RequirementList from './RequirementList'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Requirement = ({header, requirements, image}) => {
  return (
    <div className='section section-requirement'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <Header {...header} />
            <div className='header-img'>
              <img
                src={getStrapiMedia(delve(image, 'url'))}
                alt={delve(image, 'alternativeText')}
              />
            </div>
          </div>
          <div className='col-lg-6'>
            <RequirementList requirements={requirements} />
          </div>
        </div>
      </div>
    </div>
  )
}

Requirement.defaultProps = {}

export default Requirement
