const paraguayCityOptions = [
  "Seleccionar",
  "Asunción",
  "Ciudad del Este",
  "San Lorenzo",
  "Capiatá",
  "Lambaré",
  "Fernando de la Mora",
  "Limpio",
  "Ñemby",
  "Mariano Roque Alonso",
  "Encarnación",
  "Pedro Juan Caballero",
  "Luque",
  "Villa Elisa",
  "Mariano Roque Alonso",
  "Presidente Franco",
  "San Antonio",
  "Villa Hayes",
  "Caaguazú",
  "Itauguá",
  "Villarrica",
  "Caacupé",
  "San Juan Bautista",
  "Colonia Mariano Roque Alonso",
  "Coronel Oviedo",
  "Pilar",
  "Itá",
  "Itacurubí de la Cordillera",
  "Ypacaraí",
  "San Bernardino",
  "Areguá",
  "Yaguarón",
  "Nueva Italia",
  "Acahay",
  "Emboscada",
  "J. Eulogio Estigarribia",
  "San Estanislao",
  "Guarambaré",
  "Arroyos y Esteros",
  "San Pedro del Paraná",
  "Ybycuí",
  "Loma Plata",
  "Santa Rita",
  "Capitán Bado",
  "Doctor Juan León Mallorquín",
  "Benjamín Aceval",
  "Obligado",
  "Naranjal",
  "Iturbe",
  "Paraguarí",
  "San Cosme y Damián",
  "Puerto Presidente Franco",
  "Ypane",
  "Tacuatí",
  "San Ignacio Guazú",
  "Caazapá",
  "Villa Florida",
  "Yuty",
  "Horqueta",
  "José Falcón",
  "Tavapy",
  "General José Eduvigis Díaz",
  "Belén",
  "Hernandarias",
  "Tobatí",
  "Bella Vista",
  "Bella Vista Norte",
  "Ygatimí",
  "San José de los Arroyos",
  "Piribebuy",
  "Ygatimí",
  "San Roque González de Santa Cruz",
  "Carapeguá",
  "Nanawa",
  "Mbocayaty del Yhaguy",
  "Yataity del Norte",
  "San Pedro del Ycuamandiyú",
  "San Pedro del Ycuamandiyú",
  "Doctor Raúl Peña",
  "Guajayvi",
  "Isla Pucú",
  "San Pedro del Ycuamandiyú",
  "Tres de Mayo",
  "Lima",
  "La Colmena",
  "Acahay",
  "Caraguatay",
  "Obligado",
  "San Juan Nepomuceno",
  "San Juan del Paraná",
  "General Artigas",
  "Tembetary",
  "Mbuyapey",
  "Mayor Julio D. Otano",
  "Yatytay",
  "Obligado",
  "Minga Guazú",
  "Tavapy",
  "Pirayú",
  "San Juan de Ñeembucú",
  "Borja",
  "Capitán Meza",
  "Borja",
  "Hohenau",
  "General Morínigo",
  "Trinidad",
  "Yuty",
  "Limpio",
  "Valenzuela",
  "Cerrito",
  "Tacuaras",
  "Areguá",
  "San Juan Bautista",
  "Lambare",
  "Bella Vista Sur",
  "Guarambare",
  "Abaí",
  "Colonia Independencia",
  "Ybytymí",
  "Obligado",
  "Fram",
  "Cambyretá",
  "Bella Vista",
  "Yatytay",
  "San Cosme y Damián",
  "Mbocayaty",
  "Puerto Rosario",
  "Santiago",
  "Santa Rosa del Aguaray",
  "Itacurubí del Rosario",
  "Paso Yobái",
  "Tebicuarymi",
  "San Ignacio de Guazú",
  "La Paz",
  "Lima",
  "Pirapó",
  "Fuerte Olimpo",
  "Hernandarias",
  "Obligado",
  "Ybycuí",
  "Colonia Fram",
  "Nueva Germania",
  "Aregua",
  "Coronel Bogado",
  "San Bernardino",
  "General Elizardo Aquino",
  "Yhú",
  "Quiindy",
  "Nueva Londres",
  "Independencia",
  "Pirapó",
  "Mbocayaty",
  "Yatytay",
  "San Pedro del Paraná",
  "San Estanislao",
  "Ypacarai",
  "Obligado",
  "Nueva Italia",
  "Obligado",
  "Puerto Rosario",
  "Arroyos y Esteros",
  "Mariscal Estigarribia",
  "General Resquin",
  "Puerto Irala",
  "Yatytay",
  "General Artigas",
  "Mariscal Estigarribia",
  "Piribebuy",
  "Carlos Antonio López",
  "Altos",
  "Isla Pucú",
  "Aregua",
  "Isla Pucú",
  "Paraguarí",
  "Yhú",
  "Carlos Antonio López",
  "Mayor Martínez",
  "Carlos Antonio López",
  "Mayor Otano",
  "Puerto Pinasco",
  "Nueva Italia",
  "Obligado",
  "Ygatimí",
  "Carapegua",
  "Coronel Bogado",
  "Acahay",
  "Yatytay",
  "Yatytay",
  "Pirapó",
  "Piribebuy",
  "Loma Plata",
  "Pirapó",
  "Aregua",
  "Mbocayaty",
  "Nueva Londres",
  "Nueva Italia",
  "Guarambaré",
  "Mbuyapey",
  "Puerto Mayor Otano",
  "Obligado",
  "Acahay",
  "General Delgado",
  "Tacuatí",
  "Caazapá",
  "Mbocayaty",
  "Mbuyapey",
  "Ygatimí",
  "Obligado",
  "Valenzuela",
  "Aregua",
  "Pirapó",
  "Pirapó",
  "San Juan de Ñeembucú",
  "Yatytay",
  "Ybycuí",
  "Mbocayaty",
  "Carapegua",
  "Tebicuarymi",
  "Aregua",
  "San Juan de Ñeembucú",
  "Guarambaré",
  "Yataity",
  "Fuerte Olimpo",
  "Aregua",
  "Nueva Londres",
  "Obligado",
  "Valenzuela",
  "Obligado",
  "Mbocayaty",
  "Ypacarai",
  "Yataity",
  "Ygatimí",
  "Ybycuí",
  "Lima",
  "Puerto Presidente Franco",
  "San Juan Bautista",
  "San Estanislao",
  "Arroyos y Esteros",
  "Altos",
  "Tebicuarymi",
  "Paraguari",
  "San Pedro del Paraná",
  "Piribebuy",
  "Guarambaré",
  "Loma Plata",
  "Nueva Londres",
  "Acahay",
  "Limpio",
  "Coronel Bogado",
  "Bella Vista Sur",
  "Ypacaraí",
  "Yataity",
  "Tavapy",
  "Ypacarai",
  "Mbuyapey",
  "Ypacaraí",
  "Puerto Mayor Otano",
  "Ybycuí",
  "Ypacarai",
  "Fuerte Olimpo",
  "Ypacaraí",
  "San Bernardino",
  "Nueva Italia",
  "Nueva Londres",
  "Coronel Bogado",
  "San Ignacio Guazú",
  "Yataity del Norte",
  "Yataity",
  "Ybycuí",
  "Colonia Fram",
  "Acahay",
  "San Cosme y Damián",
  "Ybycuí",
  "Fuerte Olimpo",
  "Coronel Bogado",
  "Villa Elisa",
  "Paso Yobái",
  "Paso Yobái",
  "San Estanislao",
  "Valenzuela",
  "Obligado",
  "Villa Florida",
  "Villa Florida",
  "Villa Florida",
  "Tebicuarymi",
  "Obligado",
  "Obligado",
  "Tebicuarymi",
  "San Ignacio Guazú",
  "Ybycuí",
  "San Juan de Ñeembucú",
  "Nueva Italia",
  "San Ignacio Guazú",
  "Saltos del Guairá",
  "Ybycuí",
  "Pirapó",
  "Puerto Casado",
  "Pirapó",
  "Puerto Casado",
  "Puerto Casado",
  "Puerto Casado",
  "San Ignacio Guazú",
  "San Juan de Ñeembucú",
];

export default paraguayCityOptions