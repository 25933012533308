import React from 'react'
import Header from '../../shared/Header'
import PhaseList from './PhaseList'

const Metodology = ({header, program_phases}) => {
  return (
    <div className='section section-metodology'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <Header {...header} />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <PhaseList phases={program_phases} />
        </div>
      </div>
    </div>
  )
}

Metodology.defaultProps = {}

export default Metodology
