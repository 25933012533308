import React, {useEffect, useState} from 'react'
import Masonry from 'masonry-layout'
import MoreReviewsButton from './MoreReviewsButton'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Reviews = ({student_reviews}) => {
  const totalPages = Math.ceil(student_reviews.length / 3)
  const [reviewsList, setReviews] = useState([])
  const [pageInfo, setPageInfo] = useState({
    totalPages: totalPages,
    currentPage: 1,
    limit: 3,
  })

  const paginate = (array, page_size, page_number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size)
  }

  useEffect(() => {
    new Masonry('.reviews-list', {})
  }, [pageInfo.currentPage])

  useEffect(() => {
    setReviews(paginate(student_reviews, pageInfo.limit, pageInfo.currentPage))
  }, [])

  return (
    <div className='section section-reviews'>
      <div className='container'>
        <div
          className={`row reviews-list ${
            pageInfo.currentPage === 1 ? 'h-100' : ''
          }`}
        >
          {reviewsList.map((item, index) => (
            <div
              className='col-12 col-md-6 col-lg-4 reviews-item py-3'
              key={index}
            >
              <div className='card' style={{marginBottom: '2rem'}}>
                <div className='card-body'>
                  <div className='user-review-info'>
                    <img
                      className='avatar-reviews'
                      src={getStrapiMedia(delve(item, 'image.url'))}
                      alt={delve(item, 'image.alternativeText')}
                    />
                    <div>
                      <h6>{item.name}</h6>
                      <span className='role'>
                        {item.role}{' '}
                        {item.company ? (
                          <span>
                            {item.company !== 'Open to work' ? '@' : ''}
                            {item.company}
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <p className='card-text'>{item.program_review}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <MoreReviewsButton
          reviews={student_reviews}
          setReviews={setReviews}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          paginate={paginate}
        />
      </div>
    </div>
  )
}

Reviews.defaultProps = {}

export default Reviews
