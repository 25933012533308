import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {StaticImage} from 'gatsby-plugin-image'
import AccordionQuestions from './AccordionQuestions'

const Questions = ({program_questions, header}) => {
  const [clicked, setClicked] = useState(false)
  const [removing, setRemoving] = useState(false)

  const handleclick = (e) => {
    e.preventDefault()
    setClicked(true)
    navigator.clipboard.writeText(e.target.innerHTML.toLowerCase())
    setTimeout(() => {
      setClicked(false)
      setRemoving(true)
      setTimeout(() => {
        setRemoving(false)
      }, 400)
    }, 1500)
  }
  return (
    <div className='section section-questions' id='faq'>
      <div className='container container-bg'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='info-questions'>
              <h2 dangerouslySetInnerHTML={{__html: header.title}} />
              <p>{header && header.description}</p>
              <div className='info-buttons'>
                <a
                  className='pbtn pbtn-primary w-100'
                  style={{fontWeight: '100'}}
                  href={`https://wa.me/573197378826?text=Hola!%20Me%20gustar%C3%ADa%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20programa%20%2F%20curso%20de%20`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <StaticImage
                    src='../../../images/whatsapp-icon.svg'
                    // quality={43}

                    formats={['AUTO', 'WEBP']}
                    alt='icon whatsapp'
                  />
                  <span>CHATEA CON NOSOTROS</span>
                </a>
                <button
                  className={`pbtn pbtn-bordered email-btn w-100 ${
                    clicked ? 'clicked' : ''
                  } ${removing ? 'removing' : ''}`}
                  href='/'
                  style={{fontWeight: '100'}}
                  onClick={handleclick}
                >
                  <StaticImage
                    src='../../../images/email-icon.svg'
                    // quality={43}
                    formats={['AUTO', 'WEBP']}
                    alt='icon email'
                  />
                  <span>INFO@MAKEITREAL.CAMP</span>
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <AccordionQuestions questions={program_questions} />
          </div>
        </div>
      </div>
    </div>
  )
}

Questions.propTypes = {
  questions: PropTypes.array,
}

Questions.defaultProps = {
  questions: [],
}

export default Questions
