import React from 'react'
import Header from '../../shared/Header'
import MentorList from './MentorList'

const Mentors = ({header, mentors}) => {
  return (
    <div className='section section-mentors'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Header {...header} />
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 p-0'>
            <MentorList mentors={mentors} />
          </div>
        </div>
      </div>
    </div>
  )
}

Mentors.defaultProps = {
  header: {},
  mentors: [],
}

export default Mentors
