import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import Icons from './Icons.js'
import './general.css'
import {StaticImage} from 'gatsby-plugin-image'
import {postFormData} from '/src/api'

const ContactUs = () => {
  const [entries, setEntries] = useState([])
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  })
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const validate = () => {
    if (formData.first_name === '') {
      setError('El nombre es requerido')
      return false
    }
    if (formData.last_name === '') {
      setError('El apellido es requerido')
      return false
    }
    if (formData.email === '') {
      setError('El correo es requerido')
      return false
    } else {
      const re = /\S+@\S+\.\S+/
      if (!re.test(formData.email)) {
        setError('El correo no es válido')
        return false
      }
    }
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      setIsLoading(true)
      postFormData('como-convertirte-en-web-developer', formData)
        .then((res) => {
          setIsLoading(false)
          setFormData({
            first_name: '',
            last_name: '',
            email: '',
          })
          setError(null)
          setSuccess('¡Gracias por suscribirte!')
        })
        .catch((err) => {
          setIsLoading(false)
          setError('Ha ocurrido un error, por favor intenta más tarde')
        })
    }
  }

  useEffect(() => {
    Axios.get('https://blog.makeitreal.camp/search.json')
      .then((res) => setEntries([res.data[0], res.data[1]]))
      .catch((err) => console.log(err))
  }, [])

  return (
    <div className='section section-ContactUs' id='contact'>
      <div className='container container-ContactUs'>
        <div className='row'>
          <div className='col-lg-4 ContactUs-Side-Container'>
            <div className='ContactUs-Side'>
              <div>
                <StaticImage
                  src='../../../images/logo-black-2x.png'
                  width={180}
                  formats={['AUTO', 'WEBP']}
                  alt='Logo Make It Real'
                />
              </div>
              <br />
              <h5 className='mt-5'>ENTRADAS DE NUESTRO BLOG</h5>
              <div>
                <p>
                  Descrubre más contenido gratuito y conoce historias de
                  nuestros egresados y equipo
                </p>
                <Icons />
              </div>
              <br />
              <div className='blog-entries'>
                {entries.map((entry, index) => (
                  <div className='blog-entries-item' key={index}>
                    <img
                      width={140}
                      quality={100}
                      className='blog-entries-img'
                      src={`https://blog.makeitreal.camp${entry.image}`}
                      alt='contact us img'
                    />
                    <h6 className='blog-title'>{entry.title}</h6>
                  </div>
                ))}
              </div>
              <div className='link-More-Entries-Container'>
                <a href='https://blog.makeitreal.camp/'>
                  Ver todos los posts →
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-8 ContactUs-Side-Container'>
            <div className='row'>
              <div className='col-lg-5'>
                <div className='img-container'>
                  <StaticImage
                    src='../../../images/web-developer-guide-book.png'
                    formats={['AUTO', 'WEBP']}
                    alt='Book make it real'
                    className='w-auto h-auto'
                  />
                </div>
              </div>
              <div className='ContactUs-Side col-lg-7'>
                <div className='info'>
                  <h6 className='color-orange'>
                    ¡Descarga nuestro e-book completamente gratis!
                  </h6>
                  <br />
                  <p>
                    Recibe además contenido exclusivo, información de nuestros
                    Bootcamps, webinars y otros eventos.
                  </p>
                  <br />
                  <form onSubmit={handleSubmit}>
                    <div className='form-ContactUs'>
                      <div className='mb-3'>
                        <label htmlFor='firstNameBook' className='form-label'>
                          NOMBRE
                        </label>
                        <input
                          type='text'
                          id='firstName-book'
                          className='form-control'
                          value={formData.first_name}
                          onChange={handleFormChange}
                          name='first_name'
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='lastNameBook' className='form-label'>
                          APELLIDO
                        </label>
                        <input
                          type='text'
                          id='lastNameBook'
                          className='form-control'
                          value={formData.last_name}
                          onChange={handleFormChange}
                          name='last_name'
                        />
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='emailBook' className='form-label'>
                          CORREO
                        </label>
                        <input
                          type='text'
                          id='emailBook'
                          className='form-control'
                          value={formData.email}
                          onChange={handleFormChange}
                          name='email'
                        />
                      </div>
                      <div className='Container-Btn-Suscribe'>
                        {/* <a
                      href='/'
                      className='pbtn pbtn-primary ContactUs-Suscribe-Btn'
                    >
                      SUSCRIBIRME
                    </a> */}
                        <button
                          type='submit'
                          className='pbtn pbtn-primary ContactUs-Suscribe-Btn'
                          disabled={isLoading}
                        >
                          SUSCRIBIRME
                        </button>
                      </div>
                    </div>
                  </form>
                  {success && (
                    <div
                      className='alert alert-success alert-dismissible fade show mt-3'
                      role='alert'
                    >
                      <strong>{success}</strong>
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='alert'
                        aria-label='Close'
                      ></button>
                    </div>
                  )}
                  {error && (
                    <div
                      className='alert alert-danger d-flex align-items-center mt-3'
                      role='alert'
                    >
                      <div>{error}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col footer'>
            <h6>
              © Todos los derechos reservados. Make It Real es una marca de La
              Nueva Educación SAS.
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactUs.defaultProps = {}

export default ContactUs
