import React from 'react'
import {Link} from 'gatsby'

const CustomLink = ({
  label,
  href,
  target,
  className = '',
  dataBsToggle,
  dataBsTarget,
  onClick = () => {},
}) => {
  return (
    <Link
      onClick={onClick}
      to={href}
      target={target}
      className={`pbtn pbtn-primary ${className}`}
      data-bs-toggle={dataBsToggle}
      data-bs-target={dataBsTarget}
    >
      {label}
    </Link>
  )
}

CustomLink.defaultProps = {}

export default CustomLink
