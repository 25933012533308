import React from 'react'
import InstallmentList from './InstallmentList'
// import Axios from 'axios';

const PaymentList = ({payments}) => {
  return (
    <div className='row payments-list'>
      <div className='col-lg-8'>
        <div className='nav nav-pills' id='pills-tab' role='tablist'>
          <div className='container-check'>
            {payments.map((payment, index) => (
              <div className='form-check' key={index}>
                <input
                  type='radio'
                  name='flexRadioDefault'
                  className={`form-check-input ${index === 0 && 'active'}`}
                  id={`pills-${payment.country}-tab`}
                  data-bs-toggle='pill'
                  data-bs-target={`#pills-${payment.country}`}
                  role='tab'
                  aria-controls={`#pills-${payment.country}`}
                  aria-selected='true'
                />
                <label
                  className='form-check-label'
                  htmlFor={`pills-${payment.country}-tab`}
                  // onClick={() => handleChangeTab(index)}
                >
                  {payment.unit}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='tab-content' id='pills-tabContent'>
        {payments.map((payment, index) => (
          <div
            key={index}
            className={`tab-pane fade ${index === 0 && 'show active'}`}
            id={`pills-${payment.country}`}
            role='tabpanel'
            aria-labelledby={`pills-${payment.country}-tab`}
          >
            <InstallmentList
              installments={payment.installments}
              unit={payment.country}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

PaymentList.defaultProps = {}

export default PaymentList
