import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'
import moment from 'moment'

const Students = ({students}) => {
  return (
    <>
      {students &&
        students.map((student, index) => (
          <div className='card mb-3 student-item' key={index}>
            <div className='img-container'>
              <img
                className='rounded-circle'
                src={getStrapiMedia(delve(student, 'image.url'))}
                alt={delve(student, 'image.alternativeText')}
                width={80}
              />
            </div>
            <div className='card-body'>
              <p className='card-text student-name mb-1'>
                {student.name}{' '}
                {student.country ? (
                  <>
                    <span>· </span>
                    <img
                      alt='country-flag'
                      className='flag-icon'
                      src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${student.country}.svg`}
                    />
                  </>
                ) : (
                  ''
                )}
              </p>
              <h5 className='card-text student-contractedIn'>
                {student.company}
              </h5>
              <p className='card-text'>
                {student.company !== 'Open to work' ? (
                  <small className='text-muted student-location-time'>
                    TIEMPO DE UBICACIÓN:{' '}
                    <b>
                      {moment(new Date(student.new_job_at)).diff(
                        new Date(student.program_finished_at),
                        'weeks'
                      )}
                    </b>{' '}
                    SEMANA
                    {moment(new Date(student.new_job_at)).diff(
                      new Date(student.program_finished_at),
                      'weeks'
                    ) > 1
                      ? 'S'
                      : ''}
                  </small>
                ) : null}
              </p>
            </div>
          </div>
        ))}
    </>
  )
}

Students.defaultProps = {}

export default Students
