import axios from 'axios'
import jsrsasign from 'jsrsasign'

export const postFormData = async (path, data) => {
  const url = `${process.env.EXTERNAL_URL}/api/${path}`
  try {
    const token = await getToken()
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

const getToken = async () => {
  const jwtSecretKey = process.env.JWT_SECRET_KEY
  const payload = {sub: 'my_app'}
  const token = createJWT(payload, jwtSecretKey, 'HS256', {expiresIn: '1h'})
  return token
}

const createJWT = (payload, secretOrPrivateKey, algorithm, options) => {
  const header = {alg: algorithm, typ: 'JWT'}
  const jwt = jsrsasign.jws.JWS.sign(
    null,
    header,
    payload,
    secretOrPrivateKey,
    options
  )
  return jwt
}
