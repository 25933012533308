import React from 'react'
import Header from '../../shared/Header'
import CompanyCards from './company-cards'

const Companies = ({header, companies}) => {
  return (
    <div className='section section-companies'>
      <div className='container'>
        <Header {...header} />
        <CompanyCards companies={companies} />
      </div>
    </div>
  )
}

Companies.defaultProps = {}

export default Companies
