import React from 'react'
import TechologiesColumn from './technologies-column'

const Technologies = ({technologies}) => {
  const middleIndex = Math.ceil(technologies?.length / 2)
  const isOdd = technologies?.length % 2 === 0

  return (
    <div className='technologies list-items'>
      {technologies && (
        <div className='container'>
          <div className='row'>
            <TechologiesColumn list={technologies.slice(0, middleIndex)} />
            <TechologiesColumn
              list={technologies.slice(
                isOdd ? -middleIndex : -(middleIndex - 1)
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}

Technologies.defaultProps = {}

export default Technologies
