import React from 'react'
import ReactMarkdown from 'react-markdown'

const AccordionQuestions = ({questions}) => {
  return (
    <div className='questions-list'>
      <div
        className='accordion'
        id='questionsAccordion'
        style={{width: '100%'}}
      >
        {questions.map((question, index) => (
          <div className='accordion-item' key={index}>
            <h2 className='accordion-header' id={`heading-${index}`}>
              <button
                className='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target={`#collapse-${index}`}
                aria-expanded='true'
                aria-controls={`collapse-${index}`}
              >
                {question.title}
              </button>
            </h2>
            <div
              id={`collapse-${index}`}
              className={`accordion-collapse collapse ${index === 0 && 'show'}`}
              aria-labelledby={`heading-${index}`}
              data-bs-parent='#questionsAccordion'
            >
              <div className='accordion-body'>
                <div className='question' key={index}>
                  <p>{<ReactMarkdown children={question.description} />}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AccordionQuestions
