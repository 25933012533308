import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'

const Hero = ({image, header, applyNowButton, backgroundColor, ctaHero}) => {
  const title = delve(header, 'title')
  const description = delve(header, 'description')
  return (
    <div className='section section-hero' style={{backgroundColor}}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='hero-info'>
              {title && (
                <h3
                  className='title'
                  dangerouslySetInnerHTML={{__html: title}}
                />
              )}
              <p>{description}</p>
              {applyNowButton && (
                <div className='apply-button-container'>
                  <button
                    className='pbtn pbtn-primary border-0 apply'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal'
                  >
                    { ctaHero || "Aplica Ahora" }
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='hero-img'>
              <img
                src={getStrapiMedia(delve(image, 'url'))}
                alt={delve(image, 'alternativeText')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = {}

export default Hero
