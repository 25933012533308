import React, {useEffect} from 'react'

const StepList = ({steps}) => {
  useEffect(() => {
    const handleScroll = async () => {
      for (let i = 0; i < steps.length; i++) {
        const stepperItem = document.querySelector(`#stepper-${i}`)
        const element = stepperItem.getBoundingClientRect()
        stepperItem.classList.remove('pending')
        stepperItem.classList.remove('semi-checked')
        stepperItem.classList.remove('checked')
        switch (true) {
          case element.y >= window.innerHeight / 1.65: // está en la parte superior de la pantalla
            stepperItem.classList.add('pending')
            break
          case element.y >= window.innerHeight / 3 &&
            element.y < window.innerHeight / 1.65: // está en la parte media de la pantalla
            stepperItem.classList.add('semi-checked')
            break
          case element.y < window.innerHeight / 3: // está en la inferior
            stepperItem.classList.add('checked')
            break
          default:
            break
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
  }, [steps.length])

  return (
    <div className='container-fluid section-steps'>
      {steps.map((step, index) => (
        <div className='row' key={index} id={`stepper-${index}`}>
          <div className='col-sm-1' />
          <div className='col-1'>
            <div className='collll item'>
              <a
                className='list-group-item list-group-item-action'
                href={`#list-item-${index}`}
              >
                <span>0{index + 1}</span>
              </a>
              {index + 1 !== steps.length && <span className='down-conector' />}
            </div>
          </div>
          <div className='col-9'>
            <div className='collll'>
              <h5 id={`list-item-${index}`}>{step.title}</h5>
              <p>{step.description}</p>
            </div>
          </div>
          <div className='col-sm-1' />
        </div>
      ))}
    </div>
  )
}

StepList.defaultProps = {}

export default StepList
