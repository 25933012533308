import React, { useState, useEffect } from 'react';
import {navigate} from 'gatsby';
import {postFormData} from '/src/api';
import { useLocation } from "@reach/router";
import paraguayCityOptions  from './paraguayCities';

const RegistrationModalId = ({redirectToUrl, leadRegistrationUrl, convertLoopEventName, programName, formHeader, formDescription, formCta}) => {
  const [formValues, setFormValues] = useState({
    accepted_terms: false,
    first_name: '',
    last_name: '',
    email: '',
    url: '',
    country_code:'',
    country: '',
    city: '',
    mobile:'',
    gender: '',
    document_type:'',
    document_number:'',
    birthday:'',
    program_name: programName,
    convertloop_event: convertLoopEventName || `applied-to-${programName}`,
    goal: '',
    experience: '',
    studies: '',
    working: ''
  });

  const location = useLocation()  
  const isMiticProgramPage = location.pathname.includes('/mitic');

  useEffect(() => {
    if (isMiticProgramPage) {
      setCountryCode('595');
      setFormValues((prevState) => ({
        ...prevState,
        country: 'Paraguay',    
        country_code: '593'
      }));
    }
  }, [isMiticProgramPage]);
    
  const countryOptions = [
    ['País de residencia *', ''],
    ['Argentina', 'AR', {data: {code: '54'}}],
    ['Bolivia', 'BO', {data: {code: '591'}}],
    ['Chile', 'CL', {data: {code: '56'}}],
    ['Colombia', 'CO', {data: {code: '57'}}],
    ['Costa Rica', 'CR', {data: {code: '506'}}],
    ['Ecuador', 'EC', {data: {code: '593'}}],
    ['El Salvador', 'SV', {data: {code: '503'}}],
    ['España', 'ES', {data: {code: '34'}}],
    ['Estados Unidos', 'US', {data: {code: '1'}}],
    ['Guatemala', 'GT', {data: {code: '502'}}],
    ['Honduras', 'HN', {data: {code: '504'}}],
    ['México', 'MX', {data: {code: '52'}}],
    ['Nicaragua', 'NI', {data: {code: '505'}}],
    ['Panamá', 'PA', {data: {code: '507'}}],
    ['Paraguay', 'PY', {data: {code: '595'}}],
    ['Perú', 'PE', {data: {code: '51'}}],
    ['Puerto Rico', 'PR', {data: {code: '1'}}],
    ['República Dominicana', 'DO', {data: {code: '1'}}],
    ['Uruguay', 'UY', {data: {code: '598'}}],
    ['Venezuela', 'VE', {data: {code: '58'}}],
  ]

  const documentOptions = [
    'Seleccionar *',
    'Documento Nacional de Identidad o Cédula',
    'Carnet o cédula de Extranjería',
    'Pasaporte'
  ];

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [countryCode, setCountryCode] = useState(null)
  const [city, setCity] = useState(null)
  const [documentType, setDocumentType] = useState(null)

  console.log(formValues)
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: fieldValue
    }));
  };

  const handleCountryChange = (e) => {
    const country = countryOptions.find((option) => option[1] === e.target.value)
    setCountryCode(country[2].data.code)
    setFormValues((prevState) => ({
      ...prevState,
      country: country[0],
      country_code: country[2].data.code
    }));
  }

  const handleCityChange = (e) => {
    const { name, value} = e.target;
    const fieldValue = value;
    const city = paraguayCityOptions.find((option) => option[0] === e.target.value)
    setCity(city)
    setFormValues((prevState) => ({
      ...prevState,
      [name]: fieldValue
    }));
  }

  const handleDocumentTypeChange = (e) => {
    const { name, value} = e.target
    const fieldValue = documentOptions[value];
    setDocumentType(e.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      [name]: fieldValue
    }));
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors(null);


    const newErrors = validateForm(formValues);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

    try {
      const response = await postFormData(leadRegistrationUrl, {applicant: formValues})
      
      if (response.message === 'Success') {
        document.querySelector('#exampleModal').classList.remove('show')
        document.querySelector('.modal-backdrop').remove()
        navigate(redirectToUrl)
      } else {
        setErrors('An error occurred while submitting the form.')
      }
    } catch (error)  {
        setErrors('An error occurred while submitting the form.')
    } finally {
       setIsLoading(false);
    };        
   
  };

  setIsLoading(false);
};

  const validateForm = (values) => {
    const errors = {};

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (!values[key]) {
          errors[key] = 'Este campo es obligatorio';
        }
      }
    }

    return errors;
  };

  return (
    <>
      <div
        className='modal modal-lg fade '
        id='exampleModal'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            
            <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              <p><strong>{formHeader}</strong></p>
              <p><small>{formDescription}</small></p>
              </h1>
            
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            <div className='modal-wrapper'>
              <form 
                id='application-form'
                className='row g-3'
                onSubmit={handleSubmit}
              >
                <div className='modal-body'>
                  
                  <div className = 'application-1'>
                    
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='first-name'>
                              Nombre:
                              <input
                                type="text"
                                id='first-name'
                                name="first_name"
                                className='form-control'
                                placeholder='Nombres'
                                value={formValues["first_name"]}
                                onChange={handleChange}
                              />
                          </label>
                          {errors['first_name'] && <div className='error-message'>{errors['first_name']}</div>}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='last-name'>
                            Apellido:
                            <input
                              type="text"
                              id='last-name'
                              name="last_name"
                              className='form-control'
                              placeholder='Apellidos'
                              value={formValues['last_name']}
                              onChange={handleChange}
                            />
                          </label>
                          { errors['last_name'] &&  <div className='error-message'>{errors['last_name']}</div>}
                        </div>
                      </div>
                    </div>
                    {!isMiticProgramPage ? (
                    <div className='row'>  
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='country'>
                            País de residencia
                          </label>
                          <select
                            name='country'
                            id='country'
                            className='form-control'
                            onChange={handleCountryChange}
                          >
                            {countryOptions.map(([name, value]) => (
                              <option key={value} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                            {errors['country'] && <div className='error-message'>{errors['country']}</div>}
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className='form-group' id='mobile-wrapper'>
                          <label htmlFor='mobile'>
                            Teléfono
                          </label>
                            <div className='input-group'>
                              <span
                                className='input-group-text'
                                id='mobile-code'
                              >
                                {!countryCode ? '+00' : `+${countryCode}`}
                              </span>
                            
                              <input
                                type='tel'
                                id='mobile'
                                name='mobile'
                                className='form-control'
                                value={formValues.mobile}
                                placeholder='Número móvil'
                                onChange={handleChange}
                              />
                            </div>
                         
                          {errors['mobile'] && <div className='error-message'>{errors['mobile']}</div>}
                        </div>
                      </div>
                     </div> ) : 
                    (
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group'> 
                          <label htmlFor='city'>
                            Ciudad de residencia en Paraguay
                          </label>
                          <select 
                            name='city'
                            id='city'
                            className='form-control'
                            onChange={handleCityChange}
                          >
                            {paraguayCityOptions.map((city, index) => (
                              <option key={index} value={city}>
                                {city}
                              </option>
                            ))}
                          </select>
                          {errors['city'] && <div className='error-message'>{errors['city']}</div>}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group' id='mobile-wrapper'>
                          <label htmlFor='mobile'>
                            Teléfono
                          </label>
                            <div className='input-group'>
                              <span
                                className='input-group-text'
                                id='mobile-code'
                              >
                                {countryOptions[15][2].data.code}
                              </span>
                            
                              <input
                                type='tel'
                                id='mobile'
                                name='mobile'
                                className='form-control'
                                value={formValues.mobile}
                                placeholder='Número móvil'
                                onChange={handleChange}
                              />
                            </div>
                         
                          {errors['mobile'] && <div className='error-message'>{errors['mobile']}</div>}
                        </div>
                      </div>
                    </div>
                    
                    )}

                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group' id='mobile-wrapper'>
                          <label htmlFor='document-type'></label>
                          Tipo de documento:
                          <select
                            name='document_type'
                            id='document-type'
                            className='form-control'
                            onChange={handleDocumentTypeChange}
                          >
                            {documentOptions.map((name, value) => (
                              <option key={value} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                            {errors['document_type'] && <div className='error-message'>{errors['document_type']}</div>}
                        </div> 
                      </div>
                                                
                      <div className='col-sm-6 form-group' >
                         <label htmlFor="document-number"></label>
                          Número de documento:
                            <input
                              type="text"
                              name='document_number'
                              id="document-number"
                              className= 'form-control'
                              value={formValues['document_number']}
                              placeholder='número'
                              onChange={handleChange}
                            />                      
                          {errors['document_number'] && <div className='error-message'>{errors['document_number']}</div>}    
                      </div>         
                    </div>

                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='gender'>
                            Me identifico como
                          </label>
                          <select
                            type="text" 
                            name='gender'
                            id='gender'
                            className='form-control'
                            value={formValues['gender']}
                            onChange={handleChange}
                          >
                            <option value='' selected>Seleccionar *</option>
                            <option value='female'>Mujer</option>
                            <option value='male'>Hombre</option>
                            <option value='LGTBQ+'>LGTBQ+</option>
                            <option value='other'>Otro</option>
                          </select>
                          {errors['gender'] && <div className='error-message'>{errors['gender']}</div>}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor='birthday'>
                            Fecha de nacimiento
                          </label>
                          <input
                            type='date'
                            id='birthday'
                            name='birthday'
                            value={formValues["birthday"]}
                            className='form-control'
                            placeholder='dd/mm/aaaa'
                            data-datetimepicker='true'
                            onChange={handleChange}
                          />
                          {errors['birthday'] && <div className='error-message'>{errors['birthday']}</div>} 
                        </div>
                      </div> 
                    </div>    

                   <div className='row'>
                    <div className='col-sm-12'>
                      <div className='form-group'>
                          <label htmlFor='email' ></label>
                            Email:
                            <input
                              type="email"
                              name="email"
                              className='form-control'
                              value={formValues['email']}
                              onChange={handleChange}
                            />
                          {errors['email'] && <div className='error-message'>{errors['email']}</div>}   
                        </div>
                      </div>
                    </div>   

                    <div className='col-sm-12'>
                    <div className='form-group'>
                      <label htmlFor='url' >
                        LinkedIn:
                      </label>
                        <input
                          type='text'
                          id='linkedin'
                          name='url'
                          className='form-control'
                          placeholder='https://www.linkedin.com/in/...'
                          value={formValues['url']}
                          onChange={handleChange}
                        />
                       {errors['url'] && <div className='error-message'>{errors['url']}</div>}
                    </div>  
                  </div>
                  <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group' id='mobile-wrapper'>
                      <label htmlFor='studies'>
                        ¿Cuál es tu nivel de estudios máximo completado?
                        <small>
                          Contar con estudios previos formales no es un
                          requisito para ser elegid@.
                        </small>
                      </label>
                      <select
                        id='studies'
                        className='form-control'
                        name='studies'
                        value={formValues['studies']}
                        onChange={handleChange}
                      >
                        <option value='' defaultValue>Seleccionar *</option>
                        <option value='Ninguno'>Ninguno</option>
                        <option value='Secundaria/bachillerato/preparatoria'>
                          Secundaria/bachillerato/preparatoria
                        </option>
                        <option value='técnico/terciario'>
                          Técnico/terciario
                        </option>
                        <option value='Carrera universitaria'>
                          Carrera universitaria
                        </option>
                        <option value='postgrado'>postgrado</option>
                      </select>
                      {errors['studies'] && <div className='error-message'>{errors['studies']}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group' id='mobile-wrapper'>
                      <label htmlFor='working'>
                        ¿Actualmente trabajas?
                        <small>
                          No es requisito haber trabajado o trabajar para ser aceptad@. Esta información es sólo para conocer tu situación laboral actual.
                        </small>
                      </label>
                      <select
                        id='working'
                        className={'form-control'}
                        name='working'
                        value={formValues['working']}
                        onChange={handleChange}
                      >
                        <option value='' defaultValue>Seleccionar *</option>
                        <option value='Si, trabajo Full-Time'>
                          Si, trabajo tiempo completo
                        </option>
                        <option value='Si, trabajo Part Time'>
                          Si, trabajo tiempo parcial
                        </option>
                        <option value='No estoy trabajando'>
                          No estoy trabajando
                        </option>
                      </select>
                      {errors['working'] && <div className='error-message'>{errors['working']}</div>}
                    </div>
                  </div>
                </div>

                  <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group' id='mobile-wrapper'>
                    <label htmlFor='goal'>
                        Por qué quieres hacer el programa con Make It Real:
                        <small>
                          Cuéntanos qué te motiva
                        </small>
                      </label>
                      <textarea
                        id='goal'
                        name='goal'
                        className= 'form-control'
                        placeholder='Cuéntanos la razón principal por la que quieres hacer este programa'
                        value={formValues['goal']}
                        onChange={handleChange}
                      ></textarea>
                      {errors['goal'] && <div className='error-message'>{errors['goal']}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group' id='mobile-wrapper'>
                      <label htmlFor='experience'>
                          ¿Cuál es tu nivel actual de conocimiento en
                          programación?
                          <small>
                            Contar con conocimientos previos no es un
                            requisito para ser aceptad@. 
                          </small>
                        </label>
                        <textarea
                          id='experience'
                          name='experience'
                          className= 'form-control'
                          placeholder='Cuéntanos de los cursos o proyectos de programación que has realizado previamente'
                          value={formValues['experience']}
                          onChange={handleChange}
                        ></textarea>
                        {errors['experience'] && <div className='error-message'>{errors['experience']}</div>}
                    </div>
                  </div>
                </div>        

                    <div className='checkbox' style={{marginTop: '50px'}}>
                      <label>
                        <input
                          type="checkbox"
                          id='terms'
                          name="accepted_terms"
                          checked={formValues['accepted_terms']}
                          onChange={handleChange}
                        />{' '}
                        Autorizo el uso de mis datos de acuerdo a la{' '}
                        <a
                          href={`${process.env.EXTERNAL_URL}/privacy-policy`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Política de Tratamiento de Información
                        </a>
                      </label>
                      {errors['accepted_terms'] && <div className='error-message'>{errors['accepted_terms']}</div>}  
                    </div>                    
                    <div className='modal-footer'>
                      <button 
                        type="submit"
                        className='pbtn pbtn-primary btn-lg'
                        disabled={isLoading}
                      >
                        {formCta}
                      </button>
                    </div>
              </div>   
            </div>                               
          </form>
        </div>
      </div>
    </div>
  </div>
</>
  );
};

export default RegistrationModalId;