import React, {useState} from 'react'
import {navigate} from 'gatsby'
import {postFormData} from '/src/api'

const ApplicationModal = ({
  label,
  steps,
  redirectToUrl,
  applicantCreationUrl, 
  programName,
  convertLoopEventName
}) => {
  const options = [
    ['País de residencia *', ''],
    ['Argentina', 'AR', {data: {code: '54'}}],
    ['Bolivia', 'BO', {data: {code: '591'}}],
    ['Chile', 'CL', {data: {code: '56'}}],
    ['Colombia', 'CO', {data: {code: '57'}}],
    ['Costa Rica', 'CR', {data: {code: '506'}}],
    ['Ecuador', 'EC', {data: {code: '593'}}],
    ['El Salvador', 'SV', {data: {code: '503'}}],
    ['España', 'ES', {data: {code: '34'}}],
    ['Estados Unidos', 'US', {data: {code: '1'}}],
    ['Guatemala', 'GT', {data: {code: '502'}}],
    ['Honduras', 'HN', {data: {code: '504'}}],
    ['México', 'MX', {data: {code: '52'}}],
    ['Nicaragua', 'NI', {data: {code: '505'}}],
    ['Panamá', 'PA', {data: {code: '507'}}],
    ['Paraguay', 'PY', {data: {code: '595'}}],
    ['Perú', 'PE', {data: {code: '51'}}],
    ['Puerto Rico', 'PR', {data: {code: '1'}}],
    ['República Dominicana', 'DO', {data: {code: '1'}}],
    ['Uruguay', 'UY', {data: {code: '598'}}],
    ['Venezuela', 'VE', {data: {code: '58'}}],
  ]
  const [step, setStep] = useState(1)
  const [country, setCountry] = useState(null)
  const [formData, setFormData] = useState({
    step2: {email: {value: ''}},
    step3: {token: {value: ''}},
    step4: {
      top_invitation_id: {value: null},
      accepted_terms: {value: false},
      first_name: {value: ''},
      last_name: {value: ''},
      birthday: {value: ''},
      gender: {value: 'female'},
      country: {value: ''},
      mobile: {value: ''},
      url: {value: ''},
      goal: {value: ''},
      experience: {value: ''},
      studies: {value: 'Ninguno'},
      working: {value: 'Si, trabajo Full-Time'},
      additional: {value: ''},
      program_name: programName,
      convertloop_event: convertLoopEventName
    },
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const handleError = (e, stepName, message) => {
    setFormData({
      ...formData,
      [stepName]: {
        ...formData[stepName],
        [e.target.name]: {
          value: e.target.value,
          error: message,
        },
      },
    })
  }

  const handleFormChange = (e, stepName) => {
    setFormData({
      ...formData,
      [stepName]: {
        ...formData[stepName],
        [e.target.name]: {
          value: e.target.value,
          error: null,
        },
      },
    })
  }

  const handleNextStep = () => {
    setStep(step + 1)
  }

  const handleCountryChange = (e, stepName) => {
    const country = options.find((option) => option[1] === e.target.value)
    setCountry(country[2].data.code)
    setFormData({
      ...formData,
      [stepName]: {
        ...formData[stepName],
        country: {
          value: e.target.value,
        },
      },
    })
  }

  const validateStep2 = () => {
    const {email} = formData.step2
    const {accepted_terms} = formData.step4
    if (email.value.length === 0) {
      handleError(
        {target: {name: 'email', value: email.value}},
        'step2',
        'Debes ingresar un email'
      )
      return false
    }

    if (!accepted_terms.value) {
      handleError(
        {target: {name: 'accepted_terms', value: accepted_terms.value}},
        'step4',
        'Debes aceptar los terminos y condiciones'
      )
      return false
    }
    return true
  }

  const validateStep3 = () => {
    const {token} = formData.step3
    if (token.value.length === 0) {
      handleError(
        {target: {name: 'token', value: token.value}},
        'step3',
        'Debes ingresar un token'
      )

      return false
    }
    return true
  }

  const validateStep4 = () => {
    const {goal, experience, additional} = formData.step4

    for (const key in formData.step4) {
      if (formData.step4.hasOwnProperty(key)) {
        const element = formData.step4[key]
        if (key !== 'url' && element.value.length === 0) {
          handleError(
            {target: {name: `${key}`, value: formData.step4[key].value}},
            'step4',
            'Este campo es requerido'
          )
          return false
        }
      }
    }

    if (goal.value.length < 100) {
      handleError(
        {target: {name: 'goal', value: goal.value}},
        'step4',
        'Debes ingresar al menos 100 caracteres'
      )
      return false
    }

    if (experience.value.length < 100) {
      handleError(
        {target: {name: 'experience', value: experience.value}},
        'step4',
        'Debes ingresar al menos 100 caracteres'
      )
      return false
    }

    if (additional.value.length < 100) {
      handleError(
        {target: {name: 'additional', value: additional.value}},
        'step4',
        'Debes ingresar al menos 100 caracteres'
      )
      return false
    }
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setError(null)
    try {
      let isValid = false
      if (step === 2) {
        isValid = validateStep2()
      } else if (step === 3) {
        isValid = validateStep3()
      } else if (step === 4 || step === 5) {
        isValid = validateStep4()
      }
      if (isValid) {
        const newObject = Object.entries(
          formData[`step${step === 5 ? 4 : step}`]
        ).reduce((acc, [key, value]) => {
          acc[key] = value.value
          return acc
        }, {})
        if (step === 2) {
          const response = await postFormData('top_invitations', newObject)
          if (response.message === 'Success') {
            setFormData({
              ...formData,
              step4: {
                ...formData.step4,
                top_invitation_id: {value: response.data.id},
              },
            })
            setStep(step + 1)
          } else {
            setError('An error occurred while submitting the form.')
          }
        } else if (step === 3) {
          const response = await postFormData( `top_invitations/${formData.step4.top_invitation_id.value}/validate`,
            newObject
          )
          if (response.message === 'Success') {
            setStep(step + 1)
          } else {
            setError('An error occurred while submitting the form.')
          }
        } else if (step === 5) {
          const data = {
            applicant: {
              ...newObject,
              email: formData.step2.email.value,
              top_invitation_id: null,
            },
            top_invitation_id: formData.step4.top_invitation_id.value,
          }
          const response = await postFormData(applicantCreationUrl, data)
          if (response.message === 'Success') {
            document.querySelector('#exampleModal').classList.remove('show')
            document.querySelector('.modal-backdrop').remove()

            navigate(redirectToUrl)
          } else {
            setError('An error occurred while submitting the form.')
          }
        }
      }
    } catch (error) {
      setError('An error occurred while submitting the form.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div
        className='modal modal-lg fade '
        id='exampleModal'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Formulario de aplicación { `al Programa ${programName}` || `` }
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            <div className='modal-wrapper'>
              {step === 1 && (
                <div className='show-application-proccess-step'>
                  <div className='modal-body'>
                    <div className='roadmap'>
                      {steps.map((step, index) => (
                        <div className='roadmap-item' key={index}>
                          <div className='circle'>{index + 1}</div>
                          <div className='item-description'>
                            <h4>{step.title}</h4>
                            <p>{step.description}</p>
                            {step.smallerText && (
                              <small>{step.smallerText}</small>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='pbtn pbtn-primary btn-lg app-next-steps'
                      onClick={() => setStep(step + 1)}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <form
                  onSubmit={(e) => handleSubmit(e, 'step2')}
                  className='enter-email-step row g-3'
                >
                  <div className='modal-body'>
                    <p style={{marginBottom: '20px'}}>
                      Para empezar ingresa tu email:
                    </p>
                    <div className='form-group'>
                      <input
                        name='email'
                        className={`form-control ${
                          formData.step2.email.error && 'is-invalid'
                        }`}
                        placeholder='Email'
                        onChange={(e) => handleFormChange(e, 'step2')}
                        value={formData.step2.email.value}
                        id='validationUsername'
                      />
                      <div
                        id='validationUsernameFeedback'
                        className='invalid-feedback'
                      >
                        {formData.step2.email.error}
                      </div>
                    </div>
                    <div className='checkbox' style={{marginTop: '50px'}}>
                      <label>
                        <input
                          type='checkbox'
                          id='terms'
                          name='accepted_terms'
                          onChange={(e) => handleFormChange(e, 'step4')}
                          checked={formData.step4.accepted_terms.value}
                        />{' '}
                        Autorizo el uso de mis datos de acuerdo a la{' '}
                        <a
                          href={`${process.env.EXTERNAL_URL}/privacy-policy`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Política de Tratamiento de Información
                        </a>
                      </label>
                    </div>
                  </div>

                  <div className='modal-footer'>
                    <button
                      type='submit'
                      className='pbtn pbtn-primary btn-lg send-email-btn'
                      disabled={isLoading}
                    >
                      Siguiente {isLoading}
                    </button>
                  </div>
                </form>
              )}
              {step === 3 && (
                <form
                  onSubmit={(e) => handleSubmit(e, 'step3')}
                  className='verification-step row g-3'
                >
                  <div className='modal-body'>
                    <p style={{marginBottom: '20px'}}>
                      Hemos enviado un correo a{' '}
                      <strong>{formData.step2.email.value}</strong> con un
                      código de verificación de 6 dígitos, ingrésalo para
                      continuar:
                    </p>
                    <div className='form-group'>
                      <input
                        name='token'
                        className={`form-control ${
                          formData.step3.token.error && 'is-invalid'
                        }`}
                        placeholder='Token'
                        onChange={(e) => handleFormChange(e, 'step3')}
                      />
                      <div id='tokenFeedback' className='invalid-feedback'>
                        {formData.step3.token.error}
                      </div>
                      <span className='help-block'>
                        ¿No has recibido el correo? Revisa que esté bien escrito
                        y que no haya llegado a spam (correo no deseado).
                      </span>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-default verification-back-btn'
                      onClick={() => setStep(step - 1)}
                    >
                      &lt;&lt; Atrás
                    </button>
                    <button
                      type='submit'
                      className='pbtn pbtn-primary btn-lg verification-btn'
                      disabled={isLoading}
                    >
                      Verificar
                    </button>
                  </div>
                </form>
              )}
              {(step === 4 || step === 5) && (
                <form
                  id='application-form'
                  onSubmit={(e) => handleSubmit(e, 'step4')}
                  className='row g-3'
                >
                  <div className='modal-body'>
                    {step === 4 && (
                      <div className='application-1'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='first-name'>Nombre</label>
                              <input
                                type='text'
                                id='first-name'
                                name='first_name'
                                value={formData.step4.first_name.value}
                                className={`form-control ${
                                  formData.step4.first_name.error &&
                                  'is-invalid'
                                }`}
                                placeholder='Nombres'
                                onChange={(e) => handleFormChange(e, 'step4')}
                              />
                              <div
                                id='first-nameFeedback'
                                className='invalid-feedback'
                              >
                                {formData.step4.first_name.error}
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='lastName'>Apellido</label>
                              <input
                                type='text'
                                id='lastName'
                                name='last_name'
                                value={formData.step4.last_name.value}
                                className={`form-control ${
                                  formData.step4.last_name.error && 'is-invalid'
                                }`}
                                placeholder='Apellidos'
                                onChange={(e) => handleFormChange(e, 'step4')}
                              />
                              <div
                                id='lastNameFeedback'
                                className='invalid-feedback'
                              >
                                {formData.step4.last_name.error}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='birthday'>
                                Fecha de nacimiento
                              </label>
                              <input
                                type='date'
                                id='birthday'
                                name='birthday'
                                value={formData.step4.birthday.value}
                                className={`form-control ${
                                  formData.step4.birthday.error && 'is-invalid'
                                }`}
                                placeholder='dd/mm/aaaa'
                                data-datetimepicker='true'
                                onChange={(e) => handleFormChange(e, 'step4')}
                              />
                              <div
                                id='birthdayFeedback'
                                className='invalid-feedback'
                              >
                                {formData.step4.birthday.error}
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='gender'>
                                Me identifico como{' '}
                              </label>
                              <select
                                name='gender'
                                id='gender'
                                onChange={(e) => handleFormChange(e, 'step4')}
                                className={`form-control ${
                                  formData.step4.gender.error && 'is-invalid'
                                }`}
                                disabled={label === 'women'}
                                defaultValue={formData.step4.gender.value}
                              >
                                <option value='male'>Hombre</option>
                                <option value='female'>Mujer</option>
                                <option value='LGTBQ+'>LGTBQ+</option>
                                <option value='other'>Otro</option>
                              </select>
                              <div
                                id='lastNameFeedback'
                                className='invalid-feedback'
                              >
                                {formData.step4.gender.error}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <div className='form-group'>
                              <label htmlFor='country'>
                                País de residencia
                              </label>
                              <select
                                name='country'
                                id='country'
                                onChange={(e) =>
                                  handleCountryChange(e, 'step4')
                                }
                                className={`form-control ${
                                  formData.step4.country.error && 'is-invalid'
                                }`}
                              >
                                {options.map(([name, value]) => (
                                  <option key={value} value={value}>
                                    {name}
                                  </option>
                                ))}
                              </select>
                              <div
                                id='countryFeedback'
                                className='invalid-feedback'
                              >
                                {formData.step4.country.error}
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className='form-group' id='mobile-wrapper'>
                              <label htmlFor='mobile'>Teléfono</label>
                              <div className='input-group'>
                                <span
                                  className='input-group-text'
                                  id='mobile-code'
                                >
                                  {!country ? '+00' : `+${country}`}
                                </span>
                                <input
                                  type='tel'
                                  className={`form-control ${
                                    formData.step4.mobile.error && 'is-invalid'
                                  }`}
                                  id='mobile'
                                  name='mobile'
                                  value={formData.step4.mobile.value}
                                  placeholder='Número móvil'
                                  onChange={(e) => handleFormChange(e, 'step4')}
                                />
                                <div
                                  id='mobileFeedback'
                                  className='invalid-feedback'
                                >
                                  {formData.step4.mobile.error}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-12'>
                            <div className='form-group'>
                              <label htmlFor='linkedin'>LinkedIn URL</label>
                              <input
                                type='text'
                                id='linkedin'
                                name='url'
                                value={formData.step4.url.value}
                                className={`form-control ${
                                  formData.step4.url.error && 'is-invalid'
                                }`}
                                placeholder='https://www.linkedin.com/in/...'
                                onChange={(e) => handleFormChange(e, 'step4')}
                              />
                              <div
                                id='linkedinFeedback'
                                className='invalid-feedback'
                              >
                                {formData.step4.url.error}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    )}
                    {step === 5 && (
                      <div className='application-2'>
                        <p style={{marginBottom: '20px'}}>
                          Por favor responde las siguientes preguntas con el
                          mayor detalle posible,{' '}
                          <strong>
                            de tus respuestas depende que continúes en el
                            proceso
                          </strong>
                          :
                        </p>
                        <div className='form-group'>
                          <label htmlFor='goal'>
                            ¿Por qué estás{' '}
                            {label === 'women' ? 'interesada' : 'interesado(a)'}{' '}
                            en el programa?
                          </label>
                          <textarea
                            id='goal'
                            name='goal'
                            className={`form-control ${
                              formData.step4.goal.error && 'is-invalid'
                            }`}
                            rows='3'
                            placeholder='¿Cuál es tu motivación? ¿Cuáles son tus objetivos?'
                            value={formData.step4.goal.value}
                            onChange={(e) => handleFormChange(e, 'step4')}
                          ></textarea>
                          <div id='goalFeedback' className='invalid-feedback'>
                            {formData.step4.goal.error}
                          </div>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='experience'>
                            ¿Cuál es tu nivel actual de conocimiento en
                            programación?
                            {label === 'women' && (
                              <small>
                                Contar con conocimientos previos no es un
                                requisito para ser aceptada
                              </small>
                            )}
                          </label>
                          <textarea
                            id='experience'
                            name='experience'
                            className={`form-control ${
                              formData.step4.experience.error && 'is-invalid'
                            }`}
                            rows='3'
                            placeholder='Cuéntanos de los cursos o proyectos de programación que has realizado previamente'
                            value={formData.step4.experience.value}
                            onChange={(e) => handleFormChange(e, 'step4')}
                          ></textarea>
                          <div
                            id='experienceFeedback'
                            className='invalid-feedback'
                          >
                            {formData.step4.experience.error}
                          </div>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='studies'>
                            ¿Cuál es tu nivel de estudios máximo completado?
                            <small>
                              Contar con estudios previos formales no es un
                              requisito para ser{' '}
                              {label === 'women' ? 'aceptada' : 'aceptado'} al
                              programa
                            </small>
                          </label>
                          <select
                            id='studies'
                            className={`form-control ${
                              formData.step4.studies.error && 'is-invalid'
                            }`}
                            name='studies'
                            value={formData.step4.studies.value}
                            onChange={(e) => handleFormChange(e, 'step4')}
                          >
                            <option value='Ninguno'>Ninguno</option>
                            <option value='Secundaria/bachillerato/preparatoria'>
                              Secundaria/bachillerato/preparatoria
                            </option>
                            <option value='técnico/terciario'>
                              Técnico/terciario
                            </option>
                            <option value='Carrera universitaria'>
                              Carrera universitaria
                            </option>
                            <option value='postgrado'>postgrado</option>
                          </select>
                          <div
                            id='studiesFeedback'
                            className='invalid-feedback'
                          >
                            {formData.step4.studies.error}
                          </div>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='working'>
                            ¿Actualmente trabajas?
                          </label>
                          <select
                            id='working'
                            className={`form-control ${
                              formData.step4.working.error && 'is-invalid'
                            }`}
                            name='working'
                            value={formData.step4.working.value}
                            onChange={(e) => handleFormChange(e, 'step4')}
                          >
                            <option value='Si, trabajo Full-Time'>
                              Si, trabajo tiempo completo
                            </option>
                            <option value='Si, trabajo Part Time'>
                              Si, trabajo tiempo parcial
                            </option>
                            <option value='No estoy trabajando'>
                              No estoy trabajando
                            </option>
                          </select>
                          <div
                            id='workingFeedback'
                            className='invalid-feedback'
                          >
                            {formData.step4.working.error}
                          </div>
                        </div>
                        <div className='form-group'>
                          <label htmlFor='additional'>
                            ¿Hay algo más que debamos saber de ti?
                          </label>
                          <textarea
                            id='additional'
                            name='additional'
                            className={`form-control ${
                              formData.step4.additional.error && 'is-invalid'
                            }`}
                            rows='3'
                            placeholder='Cuéntanos en al menos 100 caracteres tus intereses, hobbies, lo que haces en tu tiempo libre'
                            value={formData.step4.additional.value}
                            onChange={(e) => handleFormChange(e, 'step4')}
                          ></textarea>
                          <div
                            id='additionalFeedback'
                            className='invalid-feedback'
                          >
                            {formData.step4.additional.error}
                          </div>
                        </div>
                      </div>
                    )}
                    {error && (
                      <div className='alert alert-danger' role='alert'>
                        {error}
                      </div>
                    )}
                  </div>
                  <div className='modal-footer'>
                    {step === 5 && (
                      <button
                        type='button'
                        className='pbtn pbtn-primary btn-lg application-step-btn'
                        onClick={() => setStep(step - 1)}
                      >
                        Anterior
                      </button>
                    )}
                    {step === 4 && (
                      <button
                        type='button'
                        className='pbtn pbtn-primary btn-lg application-step-btn'
                        onClick={handleNextStep}
                      >
                        Siguiente
                      </button>
                    )}
                    {step === 5 && (
                      <button
                        type='submit'
                        className='pbtn pbtn-primary btn-lg'
                        disabled={isLoading}
                      >
                        { formCta || `Enviar` }
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicationModal
