import React from 'react'
import Header from '../../shared/Header'
import PaymentList from './PaymentList'

const Pricing = ({header, payments}) => {
  return (
    <div id='payments' className='section section-pricing'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <Header {...header} />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <PaymentList payments={payments} />
        </div>
      </div>
    </div>
  )
}

Pricing.defaultProps = {}

export default Pricing
