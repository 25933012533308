import React from 'react';

const PaymentModalProinnovate = ( {paymentHeader, paymentDescription,ctaCardPaymentOne, ctaCardPaymentTwo }) => {

  return (
    <>
      <div
        className='modal modal-lg fade '
        id='exampleModal'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>

            <div className='modal-wrapper'>
              <div className='modal-body'>
                <div className='container'>
                  <div className='row'>
                    <h3><strong>{paymentHeader}</strong></h3>
                    <p>{paymentDescription}</p>  

                     <div className='col-sm-6'>
                      <a
                        href='https://buy.stripe.com/eVacOC9RC9uK6dyfZ2'
                        className='pbtn pbtn-bordered-fulfilled border-0 apply'
                        target='_blank' 
                        rel='noopener noreferrer'
                      >
                        <strong>{ctaCardPaymentTwo || "Separa tu cupo"}</strong>
                      </a>
                    </div>

                    <div className='col-sm-6'>                  
                      <div className='apply-button-container'>
                        <a
                          href='https://buy.stripe.com/7sI4i62pa9uKdG028e'
                          className='pbtn pbtn-primary border-0 apply'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <strong>{ctaCardPaymentOne || "Separa tu cupo"}</strong>
                        </a>
                      </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default PaymentModalProinnovate;