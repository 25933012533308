import React from 'react'

const Header = ({label, title, description}) => {
  return (
    <div className='content-section-heading'>
      <h3 className='label'>{label}</h3>
      <h2 className='title'>{title}</h2>
      <p className='description'>{description}</p>
    </div>
  )
}

export default Header
