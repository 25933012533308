import React from 'react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'

import {
  Achievement,
  Companies,
  Hero,
  HeroProinnovate,
  Mentors,
  Metodology,
  Pricing,
  Process,
  Requirement,
  Resume,
  Reviews,
  Testimonials,
  Why,
  OurStory,
  Questions,
  ApplicationForm,
  ApplicationFormProinnovate,
  ApplicationModal,
  RegistrationModal,
  RegistrationModalId,
  ProinnovateForm,
  PaymentModalProinnovate,
  Sponsor,
} from '../../blocks'

const getBlockComponent = ({__typename, ...rest}, index) => {
  let Block
  switch (__typename) {
    case 'StrapiComponentBlocksAchievement':
      Block = Achievement
      break
    case 'StrapiComponentBlocksCompanies':
      Block = Companies
      break
    case 'StrapiComponentBlocksHero':
      Block = Hero
      break
    case 'StrapiComponentBlocksHeroProinnovate':
      Block = HeroProinnovate
      break
    case 'StrapiComponentBlocksMentors':
      Block = Mentors
      break
    case 'StrapiComponentBlocksMetodology':
      Block = Metodology
      break
    case 'StrapiComponentBlocksPricing':
      Block = Pricing
      break
    case 'StrapiComponentBlocksProcess':
      Block = Process
      break
    case 'StrapiComponentBlocksRequirement':
      Block = Requirement
      break
    case 'StrapiComponentBlocksResume':
      Block = Resume
      break
    case 'StrapiComponentBlocksReviews':
      Block = Reviews
      break
    case 'StrapiComponentBlocksTestimonials':
      Block = Testimonials
      break
    case 'StrapiComponentBlocksWhy':
      Block = Why
      break
    case 'StrapiComponentSharedCardDetail':
      Block = OurStory
      break
    case 'StrapiComponentBlocksQuestions':
      Block = Questions
      break
    case 'StrapiComponentBlocksApplicationForm':
      Block = ApplicationForm
      break
    case 'StrapiComponentBlocksApplicationFormProinnovate':
      Block = ApplicationFormProinnovate
      break
    case 'StrapiComponentSharedApplyNow':
      Block = ApplicationModal
      break
    case 'StrapiComponentBlocksRegistrationModal':
      Block = RegistrationModal
      break
    case 'StrapiComponentBlocksRegistrationModalId':
      Block = RegistrationModalId
      break
    case 'StrapiComponentBlocksProinnovateForm':
      Block = ProinnovateForm
      break
    case 'StrapiComponentBlocksPaymentModalProinnovate':
      Block = PaymentModalProinnovate
      break  
    case 'StrapiComponentBlocksSponsor':
      Block = Sponsor
      break
    default:
      Block = null
  }

  return Block ? <Block key={`index-${index}`} {...rest} /> : null
}

const BlockManager = ({blocks, seo, title}) => {
  return (
    <div
      className='pages-layout'
      data-bs-spy='scroll'
      data-bs-target={`#page-${title.replace(/\s+/g, '-')}`}
    >
      {seo && (
        <Helmet>
          {seo.metaTitle && <title>{seo.metaTitle}</title>}
          {seo.metaDescription && (
            <meta name='description' content={seo.metaDescription} />
          )}
          {seo.keywords && <meta name='keywords' content={seo.keywords} />}
          {seo.metaRobots && <meta name='robots' content={seo.metaRobots} />}
          {seo.metaViewport && (
            <meta name='viewport' content={seo.metaViewport} />
          )}
          {seo.metaTitle && (
            <meta property='og:title' content={seo.metaTitle} />
          )}
          {seo.metaDescription && (
            <meta property='og:description' content={seo.metaDescription} />
          )}
          {seo.metaImage && (
            <meta property='og:image' content={seo.metaImage.url} />
          )}
          {seo.structuredData && (
            <script type='application/ld+json'>
              {JSON.stringify(seo.structuredData)}
            </script>
          )}
          {seo.canonicalURL && <link rel='canonical' href={seo.canonicalURL} />}
        </Helmet>
      )}
      {process.env.NODE_ENV === 'production' && (
        <Helmet>
          <script>
            {`
              !function(t,e,n,s) { t.DPEventsFunction=s,t[s]=t[s] || function() { (t[s].q=t[s].q||[]).push(arguments) }; var c=e.createElement("script"),o=e.getElementsByTagName("script")[0]; c.async=1,c.src=n,o.parentNode.insertBefore(c,o); }(window, document, "//convertloopg.us/v1/loop.min.js", "_dp");

              _dp("configure", { appId: "ed9f94bd" });
              _dp("pageView");
            `}
          </script>
          {/* <!-- Facebook Pixel Code --> */}
          <script>
            {`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','//connect.facebook.net/en_US/fbevents.js');

          fbq('init', '714033292065174');
          fbq('track', "PageView");`}
          </script>
          <noscript>
            {` 
            <img
              height='1'
              width='1'
              style='display:none'
              src='https://www.facebook.com/tr?id=714033292065174&ev=PageView&noscript=1'
            />
            `}
          </noscript>
          {/* <!-- End Facebook Pixel Code --> */}
          {/* <!-- Google Tag Manager --> */}
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NC7GBT');
          `}</script>
          {/* <!-- End Google Tag Manager --> */}
        </Helmet>
      )}
      {blocks.map(getBlockComponent)}
    </div>
  )
}

BlockManager.defaultProps = {
  blocks: [],
  title: '',
}

BlockManager.propTypes = {
  blocks: PropTypes.array,
  seo: PropTypes.object,
  title: PropTypes.string,
}

export default BlockManager
