import $ from 'jquery'

export const addCarouselSwipeGesture = () => {
    $(".carousel").on("swiperight", () => {
        alert('a')
        $(this).carousel("prev");
    });

    $(".carousel").on("swipeleft", () => {
        $(this).carousel("next");
    });
}