import React from 'react'

const RequirementList = ({requirements}) => {
  return (
    <div className='requirements'>
      {requirements.map((item, index) => (
        <div className='requirement' key={`requirement-${index}`}>
          {item.title && <h3 className='title-item'>{item.title}</h3>}
          {item.description && <p className='title-desc'>{item.description}</p>}
        </div>
      ))}
    </div>
  )
}

RequirementList.defaultProps = {}

export default RequirementList
