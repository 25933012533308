import React from 'react'
import delve from 'dlv'
import {Link} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

const Logo = ({button}) => {
  return (
    <Link
      className='navbar-brand d-flex align-items-center'
      to={delve(button, 'href')}
    >
      <span className='bs-icon-sm bs-icon-circle bs-icon-primary d-flex justify-content-center align-items-center me-2 bs-icon'>
        <StaticImage
          src='../../../images/logo-white-2x.png'
          width={180}
          quality={43}
          formats={['AUTO', 'WEBP']}
          alt='Logo make it real'
        />
      </span>
    </Link>
  )
}

export default Logo
