import React from 'react'
import Header from '../../shared/Header'
import StepList from './StepList'

const Process = ({header, steps}) => {
  return (
    <div className='section section-process'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <Header {...header} />
            <br />
            <StepList steps={steps} />
          </div>
        </div>
      </div>
    </div>
  )
}

Process.defaultProps = {}

export default Process
