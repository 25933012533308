import React from 'react'
import Installment from './Installment'

const InstallmentList = ({installments}) => {
  return (
    <div className='container'>
      <div className='row justify-content-center installments-list'>
        {installments.map((installment, index) =>
          <Installment installment={installment} index={index} />
        )}
      </div>
    </div>
  )
}

InstallmentList.defaultProps = {}

export default InstallmentList
