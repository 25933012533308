import React from 'react'
import delve from 'dlv'
import Logo from './logo'
import CustomLink from '../../shared/CustomLink'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'

const Navigation = ({navigation, location}) => {
  const isProinnovatePage = location.pathname.startsWith('/programa-becas-de-bootcamp-proinnovate-2024');


  const groupedLinks = navigation.links.filter(item => item.groupTitle !== null)
  const groupedData = groupedLinks.reduce((groups, item) => {
    const group = groups.get(item.groupTitle) || [];
    group.push(item);
    groups.set(item.groupTitle, group);
    return groups;
  }, new Map());

  const links = navigation.links.filter((item) => {
    return !item.isExternal || item.label === 'Ingresar'
  })
  const handleCloseModal = () => {
    document.querySelector('#navcol-1').classList.remove('show')
  }
  return (
    <nav
      className='navbar navbar-dark navbar-expand-md sticky-top navbar-shrink py-3'
      id='mainNav'
    >
      <div className='container-fluid'>
        <Logo button={delve(navigation, 'leftButton')} />
        <button
          data-bs-toggle='collapse'
          className='navbar-toggler'
          data-bs-target='#navcol-1'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navcol-1'>
          <div className='list w-100'>
            <div className='container-list'>
              <div className='w-100 text-end'>
                <button
                  data-bs-toggle='collapse'
                  className='navbar-toggler float-end'
                  data-bs-target='#navcol-1'
                >
                  <StaticImage
                    src='../../../images/close.svg'
                    alt='close button'
                  />
                </button>
              </div>
              <div className='navbar-nav'>
                {Array.from(groupedData, ([key, value]) => (
                  <section className='nav-item dropdown' key={key}>
                    <a
                      className='nav-link'
                      href='/.'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {key}
                    </a>
                    <ul className='dropdown-menu'>
                      {value.map((link, index) => (
                        <li className='nav-item' key={index}>
                          <a
                            onClick={handleCloseModal}
                            className='nav-link'
                            href={delve(link, 'href')}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {delve(link, 'label')}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </section>
                ))}
                {delve(navigation, 'links') &&
                  links.map((link, index) => (
                    <section className='nav-item' key={index}>
                      <Link className='nav-link' to={delve(link, 'href')} onClick={handleCloseModal}>
                        {delve(link, 'label')}
                      </Link>
                    </section>
                  ))}
                {delve(navigation, 'rightButton') && (
                  <CustomLink
                    onClick={() => handleCloseModal()}
                    className='inner-navbar-btn'
                    href={delve(navigation, 'rightButton.href')}
                    target={delve(navigation, 'rightButton.target')}
                    label={delve(navigation, 'rightButton.label')}
                    dataBsToggle='modal'
                    dataBsTarget='#exampleModal'
                  />
                )}
              </div>
            </div>
          </div>
          <div>
         
            { isProinnovatePage &&  
            
              delve(navigation, 'rightButton') && (
                <CustomLink
                  className='pbtn-bordered pbtn-navbar-adjust'
                  href='https://inngenius.proinnovate.gob.pe/'
                  target='_blank'
                  label='SEPARA TU CUPO'
                />

              )
            }
          </div>

          {delve(navigation, 'rightButton') && (
            <CustomLink
              className='outter-navbar-btn'
              href={delve(navigation, 'rightButton.href')}
              target={delve(navigation, 'rightButton.target')}
              label={isProinnovatePage ? delve(navigation, 'rightButton.labelText'): delve(navigation, 'rightButton.label')}
              dataBsToggle='modal'
              dataBsTarget='#exampleModal'
            />
          )}

          <div>
          
          </div>
        </div>
      </div>
    </nav>
  )
}

Navigation.defaultProps = {}

export default Navigation