import React from 'react'
import delve from 'dlv'
import {getStrapiMedia} from '../../../utils'
import 'bootstrap/dist/css/bootstrap.min.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const MentorList = ({mentors}) => {
  const settings = {
    focusOnSelect: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
  }
  return (
    <div className='mentors-list'>
      <div className='container-fluid'>
        <Slider {...settings}>
          {mentors.map((mentor, i) => (
            <div className='card mentor-item' key={`mentor-${i}`}>
              <div className='cont-mentor'>
                <div className='mentor-item-avatar'>
                  <img
                    src={getStrapiMedia(delve(mentor, 'avatar.url'))}
                    className='rounded-circle'
                    alt={delve(mentor, 'avatar.alternativeText')}
                  />
                </div>
                <div className='card-body mentor-item-content'>
                  <p className='card-title'>{mentor.name}</p>
                  <div className='mentor-company d-flex justify-content-center'>
                    <img
                      src={getStrapiMedia(delve(mentor, 'company_logo.url'))}
                      alt={delve(mentor, 'company_logo.alternativeText')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

MentorList.defaultProps = {}

export default MentorList
